import { ServiceType } from '@med-standarts/api';
import { useCallback, useEffect, useState } from 'react';
import { MenuSectionType } from '../models/menu-section-type';
import { StandartSection } from '../models/standart-section.model';
import { ServiceSection } from '../models/service-section.model';
import { StandartStateData } from '../models/standart-state.model';

export function useExistsMenuSections(standart: StandartStateData | undefined) {
    const [existsSections, setExistsSection] = useState<Map<StandartSection, ServiceType[]>>(new Map());

    useEffect(() => {
        if (!standart) return;

        let actualSectionsMap = new Map<StandartSection, ServiceType[]>();
        if (standart.info) {
            actualSectionsMap.set(StandartSection.Info, []);
        }
        if (standart.diagnosisServices) {
            actualSectionsMap.set(StandartSection.Diagnostics, setServiceSectionsToMap(standart.diagnosisServices));
        }
        if (standart.treatmentServices) {
            actualSectionsMap.set(StandartSection.Treatment, setServiceSectionsToMap(standart.treatmentServices));
        }
        if (standart.medications) {
            actualSectionsMap.set(StandartSection.Medications, []);
        }
        if (standart.diets) {
            actualSectionsMap.set(StandartSection.Diets, []);
        }
        setExistsSection(actualSectionsMap);
    }, [standart]);

    const getIsSectionExists = useCallback(
        (section: MenuSectionType): boolean => {
            if (section[1]) {
                return !!existsSections.get(section[0])?.includes(section[1]);
            } else {
                return existsSections.has(section[0]);
            }
        },
        [existsSections]
    );

    return { existsSections, getIsSectionExists };
}

function setServiceSectionsToMap(serviceSection: ServiceSection[]) {
    return serviceSection
        .map((ds) => {
            const serviceType = ds.services[0]?.type;
            if (serviceType) {
                return serviceType;
            }
            return undefined;
        })
        .filter((ds) => ds !== undefined) as ServiceType[];
}
