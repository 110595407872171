import styled from 'styled-components/macro';
import * as StandartCard from '../standart-card/standart-card.styles';
import * as Loader from 'components/base/loader/loader.styles';
import * as EmptyState from 'components/partial/empty-state/empty-state.styles';

export const StandartListContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${StandartCard.StandartCardContainer} {
        margin-bottom: 15px;
    }

    ${Loader.LoaderContainer} {
        margin: 10px auto;
    }

    ${EmptyState.EmptyStateContainer} {
        margin-top: 80px;
    }
`;
