import React from 'react';
import { IconProps, IconType } from './icon.types';
import { ReactComponent as Logo } from 'assets/icons/ic-logo.svg';
import { ReactComponent as Search } from 'assets/icons/ic-search.svg';
import { ReactComponent as Loader } from 'assets/icons/ic-loader.svg';
import { ReactComponent as Arrow } from 'assets/icons/ic-arrow.svg';
import { ReactComponent as List } from 'assets/icons/ic-list.svg';
import { ReactComponent as Puls } from 'assets/icons/ic-puls.svg';
import { ReactComponent as Heart } from 'assets/icons/ic-heart.svg';
import { ReactComponent as Pill } from 'assets/icons/ic-pill.svg';
import { ReactComponent as Cookie } from 'assets/icons/ic-cookie.svg';
import { ReactComponent as Cross } from 'assets/icons/ic-cross.svg';

const Icon: React.FC<IconProps> = (props) => {
    const { type, ...otherProps } = props;
    switch (type) {
        case IconType.Logo:
            return <Logo {...otherProps} />;
        case IconType.Search:
            return <Search {...otherProps} />;
        case IconType.Loader:
            return <Loader {...otherProps} />;
        case IconType.Arrow:
            return <Arrow {...otherProps} />;
        case IconType.List:
            return <List {...otherProps} />;
        case IconType.Puls:
            return <Puls {...otherProps} />;
        case IconType.Heart:
            return <Heart {...otherProps} />;
        case IconType.Pill:
            return <Pill {...otherProps} />;
        case IconType.Cookie:
            return <Cookie {...otherProps} />;
        case IconType.Cross:
            return <Cross {...otherProps} />;
        default:
            return null;
    }
};

export default Icon;
