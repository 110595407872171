import { Service } from '@med-standarts/api';
import Typography from 'components/base/typography/typography';
import React from 'react';
import * as Grid from 'components/base/grid/grid.styles';
import * as S from './service-list.styles';
import Checkbox from 'components/base/checkbox/checkbox';
import useStandartContext from 'pages/standart/hooks/useStandartContext';
import { StandartSection } from 'pages/standart/providers/models/standart-section.model';

interface Props {
    title: string;
    services: Service[];
    sectionType: StandartSection;
}

const ServiceList = React.forwardRef<HTMLDivElement, Props>(({ title, services, sectionType }, ref) => {
    const { switchRecommendation } = useStandartContext();

    if (services.length === 0) {
        return null;
    }
    return (
        <S.Container ref={ref}>
            <Typography.H1>{title}</Typography.H1>
            {services.map((service, ind) => (
                <S.ServiceItem key={ind}>
                    <Checkbox
                        id={sectionType + service.code}
                        onChange={(value) => switchRecommendation(service.name, value ? 'add' : 'remove')}
                        label={service.name}
                    />
                    <Grid.Row>
                        <S.ValueContainer>
                            <Typography.SPAN>Частота: </Typography.SPAN>
                            <Typography.SPAN>{service.frequency}</Typography.SPAN>
                        </S.ValueContainer>
                        <S.ValueContainer>
                            <Typography.SPAN>Кратность применения: </Typography.SPAN>
                            <Typography.SPAN>{service.multiplicity}</Typography.SPAN>
                        </S.ValueContainer>
                    </Grid.Row>
                </S.ServiceItem>
            ))}
        </S.Container>
    );
});

export default ServiceList;
