import React, { useRef } from 'react';
import * as S from './input.styles';

type TextareaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

interface Props {
    error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, Props & TextareaProps>((props, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement>();
    return (
        <S.InputContainer>
            <S.Textarea
                error={!!props.error}
                autoComplete="off"
                {...(props as TextareaProps)}
                ref={(node: HTMLTextAreaElement) => {
                    textareaRef.current = node;
                    if (typeof ref === 'function') {
                        ref(node);
                    } else if (ref) {
                        (ref as React.MutableRefObject<HTMLTextAreaElement>).current = node;
                    }
                }}
            />
        </S.InputContainer>
    );
});

export default Textarea;
