import React from 'react';
import Header from './components/header/header';
import { LayoutContainer, BodyContainer } from './main-layout.styles';

interface Props {
    bodyType: 'narrow' | 'wide';
}

const MainLayout: React.FC<Props> = (props) => {
    const { bodyType, children } = props;
    return (
        <LayoutContainer>
            <Header />
            <BodyContainer type={bodyType}>{children}</BodyContainer>
        </LayoutContainer>
    );
};

export default MainLayout;
