import styled, { keyframes } from 'styled-components';
import { Colors } from 'styles/colors';
import { OverlayStyle } from 'styles/common';

const openModalAnimation = keyframes`
    0% {
        top: 45%;
        opacity: 0;
        transform: translate(-50%, -50%) scaleX(0.8) scaleY(0.8);
    }
    100% {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%) scaleX(1) scaleY(1);
    }
`;

const hideModalAnimation = keyframes`
    0% {
        top: 50%;
        opacity: 1;
        transform: translate(-50%, -50%) scaleX(1) scaleY(1);
    }
    100% {
        top: 45%;
        opacity: 0;
        transform: translate(-50%, -50%) scaleX(0.8) scaleY(0.8);
    }
`;

export const CloseButton = styled.div`
    cursor: pointer;
`;

export const ModalContainer = styled.div<{ active: boolean }>`
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(0.8) scaleY(0.8);
    background-color: ${Colors.White};
    width: 90%;
    max-width: 720px;
    border-radius: 3px;
    z-index: 1001;

    animation: ${({ active }) => (active ? openModalAnimation : hideModalAnimation)} 0.3s;
    animation-fill-mode: forwards;

    ${CloseButton} {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1002;
    }
`;

const openOverlayAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const hideOverlayAnimation = keyframes`
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0;
    }
    100% {
        visibility: hidden;
    }
`;

export const Overlay = styled.div<{ active: boolean }>`
    ${OverlayStyle};
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    animation: ${({ active }) => (active ? openOverlayAnimation : hideOverlayAnimation)} 0.3s forwards;
    z-index: 1000;
`;
