import styled, { css } from 'styled-components';

const CommonStyle = css`
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
`;

const H1 = styled.h1`
    ${CommonStyle}
`;
const P = styled.p`
    ${CommonStyle}
`;
const SPAN = styled.span`
    ${CommonStyle}
`;

const Typography = {
    H1,
    P,
    SPAN,
};

export default Typography;
