import styled, { keyframes } from 'styled-components/macro';
import * as Button from 'components/base/button/button.styles';
import * as Input from 'components/base/input/input.styles';
import { Colors } from 'styles/colors';

const fadeTextAnimation = keyframes`
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Title = styled.div`
    font-size: 24px;
    margin-bottom: 24px;
`;

export const CopyStatusText = styled.div<{ fadeTime: number; isSuccessful: boolean }>`
    display: flex;
    align-items: center;
    color: ${({ isSuccessful }) => (isSuccessful ? Colors.Blue : Colors.Red)};
    animation: ${fadeTextAnimation} ${({ fadeTime }) => `${fadeTime}s`} linear;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    ${Button.ButtonContainer} {
        width: 221px;
        flex-shrink: 0;
        margin-left: 15px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 32px;
    width: 720px;

    ${Input.Textarea} {
        margin-bottom: 24px;
        padding: 16px 43px 16px 16px;
        height: 200px;
    }
`;
