import { useContext } from 'react';
import { SearchStandartsContext } from '../providers/search-standarts-provider/search-standarts-provider';

export default function useSearchStandartsContext() {
    const context = useContext(SearchStandartsContext);
    if (!context) {
        throw new Error('useSearchStandartsContext must be used within a SearchStandartsProvider');
    }
    return context;
}
