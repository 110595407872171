import Checkbox from 'components/base/checkbox/checkbox';
import Typography from 'components/base/typography/typography';
import useStandartContext from 'pages/standart/hooks/useStandartContext';
import { StandartSection } from 'pages/standart/providers/models/standart-section.model';
import React from 'react';
import * as S from './checkbox-list.styles';

interface Props {
    title: string;
    items: { name: string; id: number }[];
    type: StandartSection;
}

const CheckboxList: React.FC<Props> = ({ title, items, type }) => {
    const { addAnchor, switchRecommendation } = useStandartContext();

    return (
        <S.Container ref={(node) => addAnchor([type, null], node)}>
            <Typography.H1>{title}</Typography.H1>
            {items.map((item) => (
                <S.Item key={item.id}>
                    <Checkbox
                        id={item.id + item.name}
                        label={item.name}
                        onChange={(value) => switchRecommendation(item.name, value ? 'add' : 'remove')}
                    />
                </S.Item>
            ))}
        </S.Container>
    );
};

export default CheckboxList;
