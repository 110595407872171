import { useContext } from 'react';
import { StandartContext } from '../providers/standart-provider';

export default function useStandartContext() {
    const context = useContext(StandartContext);
    if (!context) {
        throw new Error('useStandartContext must be used within a StandartProvider');
    }
    return context;
}
