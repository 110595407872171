import * as React from 'react';
import Icon from '../icon/icon';
import { IconType } from '../icon/icon.types';
import { ButtonContainer, IconContainer } from './button.styles';
import { ButtonContainerProps } from './button.types';

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface Props {
    label: string;
    icon?: IconType;
}

const Button: React.FC<Props & ButtonContainerProps & HTMLButtonProps> = (props) => {
    return (
        <ButtonContainer {...props}>
            {!!props.icon && (
                <IconContainer>
                    <Icon type={props.icon} />
                </IconContainer>
            )}
            {props.label}
        </ButtonContainer>
    );
};

Button.defaultProps = {
    theme: 'blue',
    size: 'medium',
    side: 'left',
};

export default Button;
