import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Label = styled.label`
    font-size: 14px;
    cursor: pointer;
    position: relative;
    line-height: 15px;

    &:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid ${Colors.LightGray1};
        border-radius: 2px;
        padding: 7px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 8px;
        transition: background-color 0.05s, border-color 0.05s;
    }
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;

    &:checked + ${Label}:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 5.5px;
        width: 3px;
        height: 6.5px;
        border: solid ${Colors.White};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    &:checked + ${Label}:before {
        background-color: ${Colors.Blue};
        border-color: ${Colors.Blue};
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
`;
