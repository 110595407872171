import Typography from 'components/base/typography/typography';
import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Title = Typography.H1;

export const StandartCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${Colors.LightGray2};
    border-radius: 3px;
    padding: 25px;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.LightGray5};

        ${Title} {
            color: ${Colors.Blue};
        }
    }

    ${Title} {
        font-size: 18px;
        margin-bottom: 16px;
        transition: color 0.3s;
    }
`;
