import Typography from 'components/base/typography/typography';
import styled, { css } from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const BackButton = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;

    ${Typography.SPAN} {
        font-size: 14px;
        color: ${Colors.Gray};
        margin-left: 8px;
    }
`;

export const NavigationList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionTitle = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        color: ${Colors.Blue};
        background-color: ${Colors.LightGray3};
        svg path {
            fill: ${Colors.Blue};
        }
    }

    svg {
        margin-right: 12px;
    }
`;

const ActiveAccordionItemStyles = css`
    background-color: ${Colors.LightGray3};
    color: ${Colors.Blue};

    svg path {
        fill: ${Colors.Blue};
    }
`;

const ActiveAccordionListItem = css`
    color: ${Colors.DarkBlue};
`;

export const AccordionListItem = styled.div<{ active: boolean }>`
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 60px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    color: ${Colors.Gray};

    &:hover {
        ${ActiveAccordionListItem};
    }

    ${({ active }) => active && ActiveAccordionListItem}
`;

export const AccordionList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionItem = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;

    ${AccordionTitle} {
        ${({ active }) => active && ActiveAccordionItemStyles}
    }
`;

export const Container = styled.div`
    width: 300px;
    background-color: ${Colors.LightGray2};
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 37px 24px 0px;
    flex-shrink: 0;

    ${BackButton} {
        margin-bottom: 25px;
    }

    ${Typography.P} {
        font-size: 18px;
        margin-bottom: 28px;
    }
`;
