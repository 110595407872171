import Typography from 'components/base/typography/typography';
import useStandartContext from 'pages/standart/hooks/useStandartContext';
import { ServiceSection as SeviceSectionModel } from 'pages/standart/providers/models/service-section.model';
import { StandartSection } from 'pages/standart/providers/models/standart-section.model';
import React from 'react';
import ServiceList from '../service-list/service-list';
import * as S from './service-section.styles';

interface Props {
    title: string;
    serviceSections: SeviceSectionModel[];
    type: StandartSection;
}

const ServiceSection: React.FC<Props> = ({ title, serviceSections, type }) => {
    const { addAnchor } = useStandartContext();

    return (
        <S.Container>
            <Typography.H1 ref={(node) => addAnchor([type, null], node)}>{title}</Typography.H1>
            {serviceSections.map((serviceList, ind) => (
                <ServiceList
                    key={ind}
                    sectionType={type}
                    ref={(node) => addAnchor([type, serviceList.type], node)}
                    services={serviceList.services}
                    title={serviceList.title}
                />
            ))}
        </S.Container>
    );
};

export default ServiceSection;
