import styled from 'styled-components/macro';
import { ScrollBarStyle } from 'styles/common';
import * as Button from 'components/base/button/button.styles';

export const StandartsContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 40px calc(50% - 510px) 0;

    ${ScrollBarStyle};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${Button.ButtonContainer} {
        width: 173px;
    }
`;
