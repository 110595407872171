import React from 'react';
import Icon from '../icon/icon';
import { IconType } from '../icon/icon.types';
import Typography from '../typography/typography';
import { Container } from './logo.styles';

interface Props {
    onClick?: () => void;
}

const Logo: React.FC<Props> = ({ onClick }) => {
    return (
        <Container onClick={onClick}>
            <Icon type={IconType.Logo} />
            <Typography.P>МедСтандарт</Typography.P>
        </Container>
    );
};

export default Logo;
