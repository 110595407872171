import { ApiResponse } from '@med-standarts/api';
import { QueryFunctionContext } from 'react-query';

export type QueryKeyWrapper<TRequest> = [string, TRequest];

export async function queryWrapper<TReturnData, TRequest>(
    apiFunction: (request: TRequest) => Promise<ApiResponse<TReturnData>>,
    context: QueryFunctionContext<QueryKeyWrapper<TRequest>>
): Promise<TReturnData> {
    const [, params] = context.queryKey;
    const { data, error }: ApiResponse<TReturnData> = await apiFunction(params);

    if (error) {
        throw error;
    } else {
        return data;
    }
}
