import Typography from 'components/base/typography/typography';
import * as Checkbox from 'components/base/checkbox/checkbox.styles';
import * as Grid from 'components/base/grid/grid.styles';
import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > ${Typography.H1} {
        padding-bottom: 16px;
        font-size: 18px;
        position: sticky;
        top: 44px;
        display: block;
        background-color: ${Colors.White};
        z-index: 10;
    }
`;

export const ServiceItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    border-bottom: 1px solid ${Colors.LightGray3};

    &:hover {
        background-color: ${Colors.LightGray};
    }

    ${Checkbox.Container} {
        margin-bottom: 16px;
    }

    & > ${Grid.Row} {
        margin-left: 27px;
    }
`;

export const ValueContainer = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-right: 32px;

    ${Typography.SPAN}:first-child {
        color: ${Colors.Gray};
        margin-right: 5px;
    }
`;
