import StandartPage from 'pages/standart/standart';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Routes } from 'routes';
import StandartsPage from '../pages/standarts/standarts';

const FreeUserSwitcher: React.FC = () => {
    return (
        <Switch>
            <Route exact path={Routes.StandartsAll} component={StandartsPage} />
            <Route exact path={Routes.StandartsFederal} component={StandartsPage} />
            <Route exact path={Routes.StandartsMedOrganization} component={StandartsPage} />
            <Route exact path={Routes.Standart} component={StandartPage} />
            <Redirect from={Routes.Root} to={Routes.StandartsFederal} />
        </Switch>
    );
};

export default FreeUserSwitcher;
