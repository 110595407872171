import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';
import { ScrollBarStyle } from 'styles/common';
import * as Header from './components/header/header.styles';

export const BodyContainer = styled.div<{ type: 'narrow' | 'wide' }>`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: ${({ type }) => (type === 'narrow' ? '0 calc(50% - 510px)' : '0')};
    overflow-y: auto;

    ${ScrollBarStyle};
`;

export const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${Colors.White};
    width: 100%;
    height: 100vh;

    ${Header.HeaderContainer} {
        flex-shrink: 0;
    }
`;
