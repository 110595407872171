import Typography from 'components/base/typography/typography';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const Item = styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid ${Colors.LightGray3};

    &:hover {
        background-color: ${Colors.LightGray};
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    ${Typography.H1} {
        font-size: 24px;
        padding-bottom: 16px;
        position: sticky;
        top: 0;
        background-color: ${Colors.White};
        display: block;
        z-index: 20;
    }
`;
