import React, { useRef } from 'react';
import Icon from '../icon/icon';
import { IconType } from '../icon/icon.types';
import Loader from '../loader/loader';
import * as S from './input.styles';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface Props {
    error?: string;
    icon?: IconType;
    isLoading?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, Props & InputProps>((props: Props & InputProps, ref) => {
    const inputRef = useRef<HTMLInputElement>();
    return (
        <S.InputContainer>
            {props.icon && (
                <S.IconContainer>
                    <Icon type={props.icon} />
                </S.IconContainer>
            )}
            <S.TextInput
                error={!!props.error}
                autoComplete="off"
                {...(props as InputProps)}
                withIcon={!!props.icon}
                ref={(node: HTMLInputElement) => {
                    inputRef.current = node;
                    if (typeof ref === 'function') {
                        ref(node);
                    } else if (ref) {
                        (ref as React.MutableRefObject<HTMLInputElement>).current = node;
                    }
                }}
            />
            {props.isLoading && <Loader />}
        </S.InputContainer>
    );
});

export default Input;
