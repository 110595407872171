import _ from 'lodash';
import Icon from 'components/base/icon/icon';
import { IconType } from 'components/base/icon/icon.types';
import Loader from 'components/base/loader/loader';
import Typography from 'components/base/typography/typography';
import useStandartContext from 'pages/standart/hooks/useStandartContext';
import { SERVICE_SUBSECTIONS } from 'pages/standart/standart.constants';
import React from 'react';
import { useHistory } from 'react-router';
import { Routes } from 'routes';
import * as S from './standart-navigation.styles';
import { StandartSection } from 'pages/standart/providers/models/standart-section.model';

const MENU_LIST = [
    { title: 'Общая информация', icon: IconType.List, type: StandartSection.Info },
    { title: 'Диагностика', icon: IconType.Puls, type: StandartSection.Diagnostics, subList: SERVICE_SUBSECTIONS },
    { title: 'Лечение', icon: IconType.Heart, type: StandartSection.Treatment, subList: SERVICE_SUBSECTIONS },
    { title: 'Лекарственные препараты', icon: IconType.Pill, type: StandartSection.Medications },
    { title: 'Диета', icon: IconType.Cookie, type: StandartSection.Diets },
];

const StandartNavigation: React.FC = () => {
    const history = useHistory();
    const { standart, activeSection, getIsSectionExists, scrollToAnchor } = useStandartContext();

    if (standart.isLoading || !standart.data) {
        return (
            <S.Container>
                <Loader />
            </S.Container>
        );
    }

    return (
        <S.Container>
            <S.BackButton onClick={() => history.push(Routes.StandartsFederal)}>
                <Icon type={IconType.Arrow} />
                <Typography.SPAN>Вернуться к поиску</Typography.SPAN>
            </S.BackButton>
            <Typography.P>Навигация по разделам</Typography.P>
            <S.NavigationList>
                {MENU_LIST.map((menuItem) => {
                    if (!getIsSectionExists([menuItem.type, null])) {
                        return null;
                    }
                    const isActive = menuItem.type === activeSection[0];
                    return (
                        <S.AccordionItem key={menuItem.type} active={isActive}>
                            <S.AccordionTitle onClick={() => scrollToAnchor([menuItem.type, null])}>
                                <Icon type={menuItem.icon} /> {menuItem.title}
                            </S.AccordionTitle>
                            <S.AccordionList>
                                {menuItem.subList?.map((subListItem) => {
                                    if (!getIsSectionExists([menuItem.type, subListItem.type])) {
                                        return null;
                                    }
                                    const isActive = _.isEqual([menuItem.type, subListItem.type], activeSection);
                                    return (
                                        <S.AccordionListItem
                                            key={subListItem.type}
                                            active={isActive}
                                            onClick={() => scrollToAnchor([menuItem.type, subListItem.type])}
                                        >
                                            {subListItem.abbreviatedTitle}
                                        </S.AccordionListItem>
                                    );
                                })}
                            </S.AccordionList>
                        </S.AccordionItem>
                    );
                })}
            </S.NavigationList>
        </S.Container>
    );
};

export default StandartNavigation;
