import React from 'react';

export interface IconProps {
    type: IconType;
    onClick?: (event: React.MouseEvent) => void;
}

export enum IconType {
    Logo = 'logo',
    Search = 'search',
    Loader = 'loader',
    Arrow = 'arrow',
    List = 'list',
    Puls = 'puls',
    Heart = 'heart',
    Pill = 'pill',
    Cookie = 'cookie',
    Cross = 'cross',
}
