import styled, { keyframes } from 'styled-components/macro';
import { Colors } from 'styles/colors';

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
`;

export const LoaderContainer = styled.div<{ active?: boolean }>`
    width: ${({ active }) => (active ? '100%' : '20px')};
    height: ${({ active }) => (active ? '100vh' : '20px')};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoaderSpinner = styled.div`
    position: relative;
    margin: auto;
    width: 20px;
    height: 20px;

    svg {
        animation: ${rotate} 1s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    circle {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: ${dash} 1.5s ease-in-out infinite;
        stroke-linecap: round;
        stroke: ${Colors.Blue};
    }
`;
