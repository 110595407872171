import MainLayout from 'components/layouts/main-layout/main-layout';
import React from 'react';
import { useRouteMatch } from 'react-router';
import RecommendationModal from './components/recommendation-modal/recommendation-modal';
import StandartContent from './components/standart-content/standart-content';
import StandartNavigation from './components/standart-navigation/standart-navigation';
import StandartProvider from './providers/standart-provider';
import { useGetStandartByIdQuery } from './queries/useGetStandartByIdQuery';
import * as S from './standart.styles';

const StandartPage: React.FC = () => {
    const match = useRouteMatch<{ id: string | undefined }>();
    const result = useGetStandartByIdQuery(Number(match.params.id));
    console.log('Data: ', result.data);

    return (
        <MainLayout bodyType="wide">
            <StandartProvider queryResult={result}>
                <S.Container>
                    <StandartNavigation />
                    <StandartContent />
                </S.Container>
                <RecommendationModal />
            </StandartProvider>
        </MainLayout>
    );
};

export default StandartPage;
