import React from 'react';
import * as S from './checkbox.styles';

interface Props {
    label?: string;
    id: string;
    defaultChecked?: boolean;
    onChange?: (value: boolean) => void;
}

const Checkbox: React.FC<Props> = ({ label, id, defaultChecked, onChange }) => {
    return (
        <S.Container>
            <S.Input id={id} defaultChecked={defaultChecked} onChange={(e) => onChange && onChange(e.target.checked)} />
            {label && <S.Label htmlFor={id}>{label}</S.Label>}
        </S.Container>
    );
};

export default Checkbox;
