import Button from 'components/base/button/button';
import Typography from 'components/base/typography/typography';
import React from 'react';
import * as S from './empty-state.styles';

interface Props {
    svgImage: React.ReactNode;
    title: string;
    description: string;
    labelButton?: string;
    onButtonClick?: () => void;
}

const EmptyState: React.FC<Props> = (props) => {
    const { svgImage, title, description, labelButton, onButtonClick } = props;
    return (
        <S.EmptyStateContainer>
            {svgImage}
            <Typography.H1>{title}</Typography.H1>
            <Typography.P>{description}</Typography.P>
            {labelButton && <Button label={labelButton} onClick={onButtonClick} />}
        </S.EmptyStateContainer>
    );
};

export default EmptyState;
