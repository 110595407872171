import { ServiceType } from '@med-standarts/api';

export const SERVICE_SUBSECTIONS = [
    { title: 'Консультации', abbreviatedTitle: 'Консультации', type: ServiceType.Consultations },
    { title: 'Лабораторные исследования', abbreviatedTitle: 'Лаб. исследования', type: ServiceType.LaboratoryTests },
    {
        title: 'Инструментальные исследования',
        abbreviatedTitle: 'Инстр. исследования',
        type: ServiceType.InstrumentalInvestigations,
    },
    { title: 'Реабилитация', abbreviatedTitle: 'Реабилитация', type: ServiceType.Reabilitation },
];
