import { ApiResponse, AxiosError, PagedRequest, PagedResponse, Standart, StandartsApi } from '@med-standarts/api';
import { QueryKeyWrapper } from 'queries/helper';
import { QueryFunction, useInfiniteQuery } from 'react-query';
import { Routes } from 'routes';

interface GetStandartsProps extends Partial<PagedRequest> {
    searchStr: string;
    path: string;
}

const getStandarts: QueryFunction<PagedResponse<Standart[]>, QueryKeyWrapper<GetStandartsProps>> = async (context) => {
    const { pageParam = 1, queryKey } = context;
    const [, params] = queryKey;
    let getStandartsFunction = StandartsApi.getFederalStandarts;
    if (params.path === Routes.StandartsMedOrganization) {
        getStandartsFunction = StandartsApi.getMoStandarts;
    }
    const { data, error }: ApiResponse<PagedResponse<Standart[]>> = await getStandartsFunction({
        ...params,
        page: pageParam,
    });

    if (error) {
        throw error;
    } else {
        return data;
    }
};

export function useGetStandartsQuery(params: GetStandartsProps) {
    return useInfiniteQuery<
        PagedResponse<Standart[]>,
        AxiosError,
        PagedResponse<Standart[]>,
        QueryKeyWrapper<GetStandartsProps>
    >(['standarts', params], getStandarts, {
        getNextPageParam: (lastPage, pages) => {
            const nextPageNumber = pages.length + 1;
            if (nextPageNumber <= lastPage.pagination.pageCount) {
                return pages.length + 1;
            }
        },
    });
}
