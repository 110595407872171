import styled from 'styled-components';
import * as Loader from 'components/base/loader/loader.styles';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    ${Loader.LoaderContainer} {
        width: 100%;
        height: 100%;
    }
`;
