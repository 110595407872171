import styled from 'styled-components/macro';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`;
