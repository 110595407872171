import Typography from 'components/base/typography/typography';
import * as ServiceList from '../service-list/service-list.styles';
import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > ${Typography.H1} {
        font-size: 24px;
        padding-bottom: 16px;
        background-color: ${Colors.White};
        position: sticky;
        top: 0;
        z-index: 20;
        display: block;
        margin-top: -1px;
    }

    ${ServiceList.Container} {
        margin-bottom: 32px;
    }
`;
