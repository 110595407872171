import { GetStandartsRequest, PagedResponse, Standart, StandartByIdResponse } from '..';
import Api from '../utils/api';

export class StandartsApi {
    static getFederalStandarts(request: GetStandartsRequest) {
        return Api.get<PagedResponse<Standart[]>>(`/standarts/federal`, { params: { ...request } });
    }

    static getMoStandarts(request: GetStandartsRequest) {
        return Api.get<PagedResponse<Standart[]>>(`/standarts/mo`, { params: { ...request } });
    }

    static getFederalStandartById(id: number) {
        return Api.get<StandartByIdResponse>(`/standarts/federal/${id}`);
    }
}
