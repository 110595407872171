import React from 'react';
import EmptyState from 'components/partial/empty-state/empty-state';
import { ReactComponent as LockEmptyStateImage } from 'assets/images/lock-empty-state.svg';

const NoAuthorizedEmptyState: React.FC = () => {
    return (
        <EmptyState
            svgImage={<LockEmptyStateImage />}
            title="Хотите создать свои медицинские стандарты?"
            description="Войдите в свой аккаунт, чтобы добавить новые или увидеть существующие"
            labelButton="Войти в аккаунт"
            onButtonClick={() => {}}
        />
    );
};

export default NoAuthorizedEmptyState;
