import styled from 'styled-components';
import Typography from '../typography/typography';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;

    ${Typography.P} {
        margin-left: 6px;
        font-weight: 900;
        font-size: 18px;
    }
`;
