import Typography from 'components/base/typography/typography';
import styled from 'styled-components/macro';
import { Colors } from 'styles/colors';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Column = styled.div`
    width: 33.33%;
    display: flex;
    flex-direction: column;
`;

export const InfoItemValue = Typography.SPAN;

export const InfoItem = styled.div`
    font-size: 14px;
    color: ${Colors.Gray};
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
`;
