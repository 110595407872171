import React, { useRef } from 'react';
import MainLayout from 'components/layouts/main-layout/main-layout';
import SearchStandartsProvider from './providers/search-standarts-provider/search-standarts-provider';
import StandartsList from './components/standarts-list/standarts-list';
import * as S from './standarts.styles';
import FilterPanel from './components/filters-panel/filter-panel';
import Typography from 'components/base/typography/typography';
import AskLoginModal from './components/ask-login-modal/ask-login-modal';

const Standarts: React.FC = () => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    return (
        <MainLayout bodyType="wide">
            <S.StandartsContainer ref={scrollContainerRef}>
                <S.Header>
                    <Typography.H1 style={{ marginBottom: 25, fontSize: 28 }}>Поиск стандартов</Typography.H1>
                    {/*<Button label="Создать стандарт МО" size="big" onClick={() => toggleAskLoginModal(true)} />*/}
                </S.Header>

                <FilterPanel />
                <StandartsList scrollContainerRef={scrollContainerRef} />
            </S.StandartsContainer>
            <AskLoginModal />
        </MainLayout>
    );
};

export default function StandartsPage() {
    return (
        <SearchStandartsProvider>
            <Standarts />
        </SearchStandartsProvider>
    );
}
