import { css } from 'styled-components';
import { Colors } from 'styles/colors';
import { ButtonContainerProps } from './button.types';

function getThemeColorStyleByRGB(R: number, G: number, B: number, coeff: number) {
    const defaultColor = `rgb(${R}, ${G}, ${B})`;
    const hoverColor = `rgb(${R + coeff}, ${G + coeff}, ${B + coeff})`;
    const activetColor = `rgb(${R + coeff * 2}, ${G + coeff * 2}, ${B + coeff * 2})`;
    return css`
        background-color: ${defaultColor};
        &:hover {
            background-color: ${hoverColor};
        }
        &:active {
            background-color: ${activetColor};
        }
    `;
}

export function getThemeColorStyle(props: ButtonContainerProps) {
    if (props.disabled) {
        return css`
            background-color: ${Colors.LightGray4};
        `;
    }
    switch (props.theme) {
        case 'gray':
            return getThemeColorStyleByRGB(235, 236, 240, -13);
        case 'red':
            return getThemeColorStyleByRGB(232, 61, 61, 25);
        case 'blue':
        default:
            return getThemeColorStyleByRGB(25, 99, 209, 25);
    }
}

export function getHeight(props: ButtonContainerProps) {
    switch (props.size) {
        case 'small':
            return '32px';
        case 'big':
        case 'medium':
        default:
            return '40px';
    }
}

export function getContentColor(props: ButtonContainerProps) {
    if (!!props.disabled) {
        return Colors.LightBlue;
    }
    switch (props.theme) {
        case 'gray':
            return Colors.Gray;
        case 'blue':
        case 'red':
        default:
            return Colors.White;
    }
}
