import Loader from 'components/base/loader/loader';
import usePaginateByScroll from 'hooks/usePaginateByScroll';
import useSearchStandartsContext from 'pages/standarts/hooks/useSearchStandartsContext';
import { useGetStandartsQuery } from '../../queries/useGetStandartsQuery';
import React, { useCallback } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { Routes } from 'routes';
import NoAuthorizedEmptyState from '../not-authorized-empty-state/no-authorized-empty-state';
import NotFoundEmptyState from '../not-found-empty-state/not-found-empty-state';
import StandartCard from '../standart-card/standart-card';
import * as S from './standarts-list.styles';

interface Props {
    scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
}

const StandartsList: React.FC<Props> = ({ scrollContainerRef }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { filters } = useSearchStandartsContext();
    const { isLoading, data, error, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetStandartsQuery({
        ...filters,
        path: match.path,
    });
    const isEmptyResult = data?.pages[0]?.data.length === 0;

    const onLoadNextPage = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage]);

    usePaginateByScroll(scrollContainerRef, onLoadNextPage);

    return (
        <S.StandartListContainer ref={scrollContainerRef}>
            {!isFetchingNextPage && !isLoading && error && <NoAuthorizedEmptyState />}
            {!isFetchingNextPage && !isLoading && isEmptyResult && <NotFoundEmptyState />}
            {data &&
                !error &&
                data.pages.map((standartsPage, ind) => (
                    <React.Fragment key={ind}>
                        {standartsPage.data.map((standart) => (
                            <StandartCard
                                title={standart.name}
                                age={standart.age.name}
                                gender={standart.gender.name}
                                termOfTreatment={standart.termOfTreatment}
                                phase={standart.phase.name}
                                stage={standart.stage.name}
                                complications={standart.complications.name}
                                medicalCareKind={standart.medicalCareKind?.name || null}
                                medicalCareConditions={standart.medicalCareConditions?.name}
                                medicalCareForm={standart.medicalCareForm.name}
                                diagnoses={standart.diags}
                                onClick={() => {
                                    console.log(generatePath(Routes.Standart, { id: standart.standartId }));

                                    history.push(generatePath(Routes.Standart, { id: standart.standartId }));
                                }}
                                key={standart.standartId}
                            />
                        ))}
                    </React.Fragment>
                ))}
            {(isFetchingNextPage || isLoading) && <Loader />}
        </S.StandartListContainer>
    );
};

export default StandartsList;
