import styled from 'styled-components';
import { getContentColor, getHeight, getThemeColorStyle } from './button.helper';
import { ButtonContainerProps } from './button.types';

export const IconContainer = styled.div`
    width: 20px;
    height: 20px;
`;

export const ButtonContainer = styled.button<ButtonContainerProps>`
    position: relative;
    display: flex;
    flex-direction: ${({ side }) => (side === 'left' ? 'row' : 'row-reverse')};
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 0 16px;
    min-width: ${({ size }) => (size === 'big' ? '168px' : '132px')};
    ${getThemeColorStyle};
    color: ${getContentColor};
    border: none;
    font-size: 14px;
    font-weight: 500;
    height: ${getHeight};
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
        cursor: default;
    }

    ${IconContainer} {
        margin: 0 10px;

        svg path {
            fill: ${getContentColor};
        }
    }
`;
