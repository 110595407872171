import React, { createContext, useState } from 'react';
import { StandartsFilters } from './models/standarts-filters';

export interface ISearchStandartsContext {
    filters: StandartsFilters;
    changeFilters: (filters: Partial<StandartsFilters>) => void;

    isAskLoginModalShowing: boolean;
    toggleAskLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchStandartsContext = createContext<ISearchStandartsContext>(null!);

const SearchStandartsProvider: React.FC = ({ children }) => {
    const [filters, setFilters] = useState<StandartsFilters>({
        searchStr: '',
    });
    const [isAskLoginModalShowing, toggleAskLoginModal] = useState<boolean>(false);

    const changeFilters = (filters: Partial<StandartsFilters>) => {
        setFilters((prevState) => ({
            ...prevState,
            ...filters,
        }));
    };

    return (
        <SearchStandartsContext.Provider
            value={{ filters, changeFilters, isAskLoginModalShowing, toggleAskLoginModal }}
        >
            {children}
        </SearchStandartsContext.Provider>
    );
};

export default SearchStandartsProvider;
