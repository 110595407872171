import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const NavigationContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
`;

export const Item = styled.div<{ isActive: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s, border-width 0.3s;

    color: ${({ isActive }) => (isActive ? Colors.Blue : Colors.DarkBlue)};
    border-bottom: solid ${Colors.Blue};
    border-bottom-width: ${({ isActive }) => (isActive ? '3px' : 0)};
`;
