import Button from 'components/base/button/button';
import Textarea from 'components/base/input/textarea';
import Modal from 'components/base/modal/modal';
import useStandartContext from 'pages/standart/hooks/useStandartContext';
import React, { useRef, useState } from 'react';
import * as S from './recommendation-modal.styles';

const FADE_TIME = 5000;

const RecommendationModal: React.FC = () => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [isCopySuccessful, setCopyStatus] = useState<boolean | null>(null);
    const { isRecModalShowing, toggleRecModal, formedRecommendations } = useStandartContext();

    const copyToClipboard = () => {
        if (textareaRef.current) {
            navigator.clipboard
                .writeText(textareaRef.current.value)
                .then(() => {
                    console.log('Successful copied to clipboard');
                    setCopyStatus(true);
                })
                .catch((error) => {
                    console.log('Failed copy to clipboard: ', error);
                    setCopyStatus(false);
                })
                .finally(() => {
                    setTimeout(() => setCopyStatus(null), FADE_TIME);
                });
        }
    };

    return (
        <Modal
            isOpen={isRecModalShowing}
            withCloseBtn={true}
            hideOnOverlayClicked={true}
            onHide={() => toggleRecModal(false)}
        >
            <S.Container>
                <S.Title>Рекомендации</S.Title>
                <Textarea defaultValue={formedRecommendations.join('\n')} ref={textareaRef} />
                <S.Footer>
                    {isCopySuccessful !== null && (
                        <S.CopyStatusText fadeTime={FADE_TIME / 1000} isSuccessful={isCopySuccessful}>
                            {isCopySuccessful
                                ? 'Успешно скопировано!'
                                : 'Копирование не удалось. Попробуйте скопировать самостоятельно'}
                        </S.CopyStatusText>
                    )}
                    <Button size="big" label="Скопировать в буфер обмена" onClick={copyToClipboard} />
                </S.Footer>
            </S.Container>
        </Modal>
    );
};

export default RecommendationModal;
