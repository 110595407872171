import Typography from 'components/base/typography/typography';
import styled from 'styled-components/macro';
import * as Button from 'components/base/button/button.styles';
import { Colors } from 'styles/colors';
import { ScrollBarStyle } from 'styles/common';

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    ${Button.ButtonContainer} {
        flex-shrink: 0;
        width: 280px;
    }
`;

export const PageTitle = styled.div`
    ${Typography.H1} {
        font-size: 28px;
        margin-bottom: 16px;
    }
    ${Typography.SPAN} {
        font-size: 14px;
        color: ${Colors.Gray};
        margin-right: 3px;
        line-height: 21px;
    }
`;

export const ScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    ${ScrollBarStyle};
`;

export const InfoContainer = styled.div`
    background-color: ${Colors.LightGray2};
    padding: 24px;
    border-radius: 3px;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 36px 0px 0px;

    ${Header} {
        margin-bottom: 24px;
        padding: 0 60px;
    }

    ${InfoContainer} {
        margin-bottom: 36px;
    }

    ${ScrollContainer} {
        padding: 0 50px 0 60px;
    }
`;
