import styled, { css } from 'styled-components/macro';
import { Colors } from 'styles/colors';
import * as Loader from 'components/base/loader/loader.styles';
import { ScrollBarStyle } from 'styles/common';

export const InputContainer = styled.div`
    position: relative;

    ${Loader.LoaderContainer} {
        position: absolute;
        right: 16px;
        top: 15px;
    }
`;

const InputStyles = css`
    border: 1px solid;
    background-color: ${Colors.White};
    height: 52px;
    border-radius: 3px;
    width: 100%;
    font-size: 16px;
    &::-webkit-clear-button,
    &::-moz-clear-button,
    &::-o-clear-button,
    &::-ms-clear-button,
    &::-webkit-inner-spin-button,
    &::-moz-inner-spin-button,
    &::-o-inner-spin-button,
    &::-ms-inner-spin-button {
        display: none;
    }

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: ${Colors.Gray};
    }

    &:hover {
        background-color: ${Colors.LightGray4};
    }

    &:focus {
        background-color: ${Colors.White};
        border-color: ${Colors.Blue};
    }

    &:disabled {
        background-color: ${Colors.LightGray4};
        border-color: ${Colors.LightGray4};
        color: ${Colors.LightBlue};

        &::placeholder {
            color: ${Colors.LightBlue};
        }
    }
`;

export const TextInput = styled.input<{ error: boolean; withIcon: boolean }>`
    ${InputStyles};
    border-color: ${({ error }) => (error ? Colors.Red : Colors.LightGray1)};
    padding-left: ${({ withIcon }) => (withIcon ? '52px' : '16px')};
`;

export const Textarea = styled.textarea<{ error: boolean }>`
    ${InputStyles};
    ${ScrollBarStyle};
    border-color: ${({ error }) => (error ? Colors.Red : Colors.LightGray1)};
`;

export const IconContainer = styled.div`
    position: absolute;
    left: 16px;
    top: 15px;
`;
