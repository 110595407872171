import { throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { MenuSectionType } from '../models/menu-section-type';

export function useScrollNavigation(onSectionChange: (currentSection: MenuSectionType) => void) {
    const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(null);
    const [anchors, setAnchors] = useState<Map<string, HTMLDivElement>>(new Map());

    const addAnchor = useCallback(
        (section: MenuSectionType, sectionElement: HTMLDivElement | null) => {
            if (sectionElement) {
                setAnchors((prev) => {
                    return prev.set(JSON.stringify(section), sectionElement);
                });
            }
        },
        [setAnchors]
    );

    const scrollToAnchor = (section: MenuSectionType) => {
        const sectionElement = anchors.get(JSON.stringify(section));
        if (scrollContainer && sectionElement) {
            const sectionElementOffset = sectionElement.offsetTop - scrollContainer.offsetTop;
            scrollContainer.scrollTo({ behavior: 'smooth', top: sectionElementOffset });
        }
    };

    useEffect(() => {
        if (!scrollContainer) return;

        const onScroll = throttle(() => {
            anchors.forEach((sectionElement: HTMLDivElement, key: string) => {
                const sectionElementOffset = sectionElement.offsetTop - scrollContainer.offsetTop - 30;
                if (
                    sectionElementOffset <= scrollContainer.scrollTop &&
                    scrollContainer.scrollTop < sectionElementOffset + sectionElement.offsetHeight
                ) {
                    onSectionChange(JSON.parse(key) as MenuSectionType);
                }
            });
        }, 300);

        scrollContainer.addEventListener('scroll', onScroll);
        return () => {
            scrollContainer.removeEventListener('scroll', onScroll);
            onScroll.cancel();
        };
    }, [scrollContainer, anchors, onSectionChange]);

    return { addAnchor, setScrollContainer, scrollToAnchor };
}
