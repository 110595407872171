import { IconType } from 'components/base/icon/icon.types';
import Input from 'components/base/input/input';
import useSearchStandartsContext from 'pages/standarts/hooks/useSearchStandartsContext';
import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import * as S from './filter-panel.styles';
import { useIsFetching } from 'react-query';
import { Routes } from 'routes';
import { useHistory, useRouteMatch } from 'react-router';

const STANDART_TYPES = [
    { title: 'Все стандарты', link: Routes.StandartsAll },
    { title: 'Федеральные стандарты', link: Routes.StandartsFederal },
    //{ title: 'Стандарты МО', link: Routes.StandartsMedOrganization },
];

const FilterPanel: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const isFetching = useIsFetching();
    const { filters, changeFilters } = useSearchStandartsContext();

    const changeQueryFilter = debounce((searchStr) => {
        changeFilters({ searchStr });
    }, 250);

    const onInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            changeQueryFilter(e.target.value);
        },
        [changeQueryFilter]
    );

    return (
        <S.FilterPanelContainer>
            <Input
                onChange={onInputChange}
                placeholder="Начните вводить код, название диагноза или симптомы"
                icon={IconType.Search}
                defaultValue={filters.searchStr}
                isLoading={!!isFetching}
            />
            <S.TypesContainer>
                {STANDART_TYPES.map(({ title, link }) => {
                    return (
                        <S.TypeItem
                            onClick={() => {
                                history.push(link);
                            }}
                            active={match.path === link}
                            key={link}
                        >
                            {title}
                        </S.TypeItem>
                    );
                })}
            </S.TypesContainer>
        </S.FilterPanelContainer>
    );
};

export default FilterPanel;
