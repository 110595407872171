import styled from 'styled-components';
import * as Input from 'components/base/input/input.styles';
import { Colors } from 'styles/colors';

export const FilterPanelContainer = styled.div`
    ${Input.InputContainer} {
        margin-bottom: 15px;
    }
`;

export const TypesContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
`;

export const TypeItem = styled.div<{ active: boolean }>`
    height: 28px;
    line-height: 28px;
    font-weight: 500;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-self: center;
    border-radius: 3px;
    color: ${({ active }) => (active ? Colors.Blue : Colors.DarkBlue)};
    background-color: ${({ active }) => (active ? 'rgba(25, 99, 209, 0.1)' : 'transparent')};
    margin-right: 8px;
    transition: background-color 0.3s;
    cursor: ${({ active }) => (active ? 'inherit' : 'pointer')};

    &:hover {
        background-color: rgba(25, 99, 209, 0.1);
    }
`;
