export enum Colors {
    White = '#fff',
    DarkBlue = '#172B4D',
    Blue = '#1963D1',
    LightBlue = '#B6C2D7',
    TransparentBlue = 'rgba(25, 99, 209, 0.1)',
    Gray = '#6B778C',
    Red = '#E83D3D',
    LightGray = '#F5F7FA',
    LightGray1 = '#DFE1E6',
    LightGray2 = '#FAFBFC',
    LightGray3 = '#EBECF0',
    LightGray4 = '#F5F5F7',
    LightGray5 = '#F5F7FA',
}
