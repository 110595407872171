import { AxiosError, StandartByIdResponse, StandartsApi } from '@med-standarts/api';
import { QueryKeyWrapper, queryWrapper } from 'queries/helper';
import { useQuery } from 'react-query';

export function useGetStandartByIdQuery(id: number) {
    return useQuery<StandartByIdResponse, AxiosError, StandartByIdResponse, QueryKeyWrapper<number>>(
        ['standart', id],
        (context) => queryWrapper(StandartsApi.getFederalStandartById, context)
    );
}
