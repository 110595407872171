import { AxiosError, Service, StandartByIdResponse } from '@med-standarts/api';
import { SERVICE_SUBSECTIONS } from 'pages/standart/standart.constants';
import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { StandartState, StandartStateData } from '../models/standart-state.model';

export function useStandartStateHandler(
    queryResult: UseQueryResult<StandartByIdResponse, AxiosError<any>>
): StandartState {
    const standartState: StandartState = useMemo(() => {
        let data: StandartStateData | undefined = undefined;

        if (queryResult.data) {
            const { diagnosisServices, treatmentServices } = queryResult.data.data;
            data = {
                info: queryResult.data.data,
                medications: queryResult.data.data.medications,
                diets: queryResult.data.data.diets,
                diagnosisServices: diagnosisServices ? getServiceLists(diagnosisServices) : null,
                treatmentServices: treatmentServices ? getServiceLists(treatmentServices) : null,
            };
        }

        return {
            ...queryResult,
            data,
        };
    }, [queryResult]);

    return standartState;
}

function getServiceLists(services: Service[]) {
    return SERVICE_SUBSECTIONS.map((serviceSubsectionType) => {
        return {
            title: serviceSubsectionType.title,
            type: serviceSubsectionType.type,
            services: services.filter((service) => service.type === serviceSubsectionType.type),
        };
    });
}
