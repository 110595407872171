import { Diagnosis } from '@med-standarts/api';
import Typography from 'components/base/typography/typography';
import StandartInfo from 'components/partial/standart-info/standart-info';
import { capitalizeString } from 'helpers';
import React from 'react';
import * as S from './standart-card.styles';
import * as Grid from 'components/base/grid/grid.styles';
import { Colors } from 'styles/colors';

interface Props {
    title: string;
    age: string | null;
    gender: string | null;
    termOfTreatment: number | null;
    phase: string | null;
    stage: string | null;
    complications: string | null;
    medicalCareKind: string | null;
    medicalCareConditions: string | number;
    medicalCareForm: string | null;
    diagnoses: Diagnosis[];
    onClick: () => void;
}

const StandartCard: React.FC<Props> = (props) => {
    return (
        <S.StandartCardContainer onClick={props.onClick}>
            <S.Title>{capitalizeString(props.title)}</S.Title>
            <Grid.Row style={{ marginBottom: 20, flexWrap: 'wrap' }}>
                {props.diagnoses.map((diag, ind) => (
                    <Typography.SPAN
                        key={diag.id}
                        style={{ color: Colors.Gray, fontSize: 14, marginRight: 3, lineHeight: '21px' }}
                    >
                        {diag.code} {diag.name}
                        {ind !== props.diagnoses.length - 1 && ','}
                    </Typography.SPAN>
                ))}
            </Grid.Row>
            <StandartInfo {...props} />
        </S.StandartCardContainer>
    );
};

export default StandartCard;
