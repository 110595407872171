import Logo from 'components/base/logo/logo';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'routes';
import Navigation from '../navigation/navigation';
import * as S from './header.styles';

const Header: React.FC = () => {
    const history = useHistory();

    return (
        <S.HeaderContainer>
            <Logo onClick={() => history.push(Routes.Root)} />
            <Navigation />
            {/*<S.LinkContainer>
                <Link to={Routes.Root}>Войти в аккаунт</Link>
            </S.LinkContainer>
            <Button label="Регистрация" size="small" />*/}
        </S.HeaderContainer>
    );
};

export default Header;
