import { AxiosError, initAxios } from '@med-standarts/api';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from 'styles/common';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/init-fonts.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

const env = (window as any)._env_;
initAxios(env.API_URL, (error: AxiosError) => {
    if (401 === error.response?.status) {
        console.log('Unauthorized');
    }
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalStyles />
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
