import EmptyState from 'components/partial/empty-state/empty-state';
import React from 'react';
import { ReactComponent as NotFoundStateImage } from './not-found-empty-state.svg';

const NotFoundEmptyState: React.FC = () => {
    return (
        <EmptyState
            svgImage={<NotFoundStateImage />}
            title="Ничего не найдено"
            description="Попробуйте изменить запрос"
        />
    );
};

export default NotFoundEmptyState;
