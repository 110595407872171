import Button from 'components/base/button/button';
import Typography from 'components/base/typography/typography';
import React from 'react';
import * as S from './standart-content.styles';
import StandartInfo from 'components/partial/standart-info/standart-info';
import ServiceSection from '../service-section/service-section';
import useStandartContext from 'pages/standart/hooks/useStandartContext';
import Loader from 'components/base/loader/loader';
import CheckboxList from '../checkbox-list/checkbox-list';
import { StandartSection } from 'pages/standart/providers/models/standart-section.model';
import { capitalizeString } from 'helpers';
import * as Grid from 'components/base/grid/grid.styles';

const StandartContent: React.FC = () => {
    const { standart, addAnchor, setScrollContainer, toggleRecModal } = useStandartContext();

    if (standart.isLoading || !standart.data) {
        return <Loader />;
    }

    const { info, diagnosisServices, treatmentServices, medications, diets } = standart.data;

    return (
        <S.Container>
            <S.Header>
                <S.PageTitle>
                    <Typography.H1>{capitalizeString(info.name)}</Typography.H1>
                    <Grid.Row style={{ flexWrap: 'wrap' }}>
                        {info.diags.map((diag, ind) => (
                            <Typography.SPAN key={diag.id}>
                                {diag.code} {diag.name}
                                {ind !== info.diags.length - 1 && ','}
                            </Typography.SPAN>
                        ))}
                    </Grid.Row>
                </S.PageTitle>
                <Button label="Сформировать рекомендации" onClick={() => toggleRecModal(true)} />
            </S.Header>
            <S.ScrollContainer ref={(node) => setScrollContainer(node)}>
                <S.InfoContainer ref={(node) => addAnchor([StandartSection.Info, null], node)}>
                    <StandartInfo
                        age={info.age.name}
                        gender={info.gender.name}
                        termOfTreatment={info.termOfTreatment}
                        phase={info.phase.name}
                        stage={info.stage.name}
                        complications={info.complications.name}
                        medicalCareKind={info.medicalCareKind?.name || null}
                        medicalCareConditions={info.medicalCareConditions.name}
                        medicalCareForm={info.medicalCareForm.name}
                    />
                </S.InfoContainer>
                {diagnosisServices && (
                    <ServiceSection
                        title="Диагностика"
                        type={StandartSection.Diagnostics}
                        serviceSections={diagnosisServices}
                    />
                )}
                {treatmentServices && (
                    <ServiceSection
                        title="Лечение"
                        type={StandartSection.Treatment}
                        serviceSections={treatmentServices}
                    />
                )}
                {medications && (
                    <CheckboxList
                        title="Лекарственные препараты"
                        items={medications}
                        type={StandartSection.Medications}
                    />
                )}
                {diets && <CheckboxList title="Диеты" items={diets} type={StandartSection.Diets} />}
            </S.ScrollContainer>
        </S.Container>
    );
};

export default StandartContent;
