import React from 'react';
import * as S from './standart-info.styles';

interface Props {
    age: string | null;
    gender: string | null;
    termOfTreatment: number | null;
    phase: string | null;
    stage: string | null;
    complications: string | null;
    medicalCareKind: string | null;
    medicalCareConditions: string | number;
    medicalCareForm: string | null;
}

const StandartInfo: React.FC<Props> = (props) => {
    const INFO_COLUMNS = [
        [
            { title: 'Возрастная категория:', value: props.age },
            { title: 'Пол:', value: props.gender },
            { title: 'Срок лечения:', value: props.termOfTreatment },
        ],
        [
            { title: 'Фаза:', value: props.phase },
            { title: 'Стадия:', value: props.stage },
            { title: 'Осложнения:', value: props.complications },
        ],
        [
            { title: 'Вид МП:', value: props.medicalCareKind },
            { title: 'Условия оказания:', value: props.medicalCareConditions },
            { title: 'Форма оказания:', value: props.medicalCareForm },
        ],
    ];
    return (
        <S.Row>
            {INFO_COLUMNS.map((col, colInd) => (
                <S.Column key={colInd}>
                    {col.map((item, itemInd) => (
                        <S.InfoItem key={itemInd}>
                            {item.title} <S.InfoItemValue>{item.value || '—'}</S.InfoItemValue>
                        </S.InfoItem>
                    ))}
                </S.Column>
            ))}
        </S.Row>
    );
};

export default StandartInfo;
