import React, { useEffect, useRef } from 'react';
import Icon from '../icon/icon';
import { IconType } from '../icon/icon.types';
import * as S from './modal.styles';

interface Props {
    isOpen: boolean;
    hideOnOverlayClicked?: boolean;
    withCloseBtn?: boolean;
    onHide?: () => void;
}

const Modal: React.FC<Props> = (props) => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.body.style.overflow = props.isOpen ? 'hidden' : 'auto';
        if (!props.isOpen) {
            setTimeout(() => {
                ref.current?.style.setProperty('display', 'none');
            }, 300);
        } else {
            ref.current?.style.setProperty('display', 'block');
        }
    }, [props.isOpen]);

    return (
        <S.Overlay
            ref={ref}
            active={props.isOpen}
            onClick={() => props.hideOnOverlayClicked && !!props.onHide && props.onHide()}
        >
            <S.ModalContainer onClick={stopPropagation} active={props.isOpen}>
                {props.withCloseBtn && (
                    <S.CloseButton onClick={props.onHide}>
                        <Icon type={IconType.Cross} />
                    </S.CloseButton>
                )}
                {props.children}
            </S.ModalContainer>
        </S.Overlay>
    );
};

const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
};

Modal.defaultProps = {
    isOpen: false,
    hideOnOverlayClicked: true,
    withCloseBtn: true,
};

export default Modal;
