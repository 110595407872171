import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

let Api: AxiosInstance = axios.create();

export interface ApiResponse<T> {
  data: T;
  error?: AxiosError;
}

export const initAxios = (
  apiUrl: string,
  errorHandler: (error: AxiosError) => void
) => {
  Api.defaults.baseURL = apiUrl;
  Api.defaults.responseType = "json";

  Api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      errorHandler && errorHandler(error);
      return Promise.resolve({ error });
    }
  );
};

export const clearAuthHeaders = () => {
  delete Api.defaults.headers.common["Authorization"];
  delete Api.defaults.headers.common["AdminAuthorization"];
};

export const setAuthHeaders = (token: string, adminToken?: string) => {
  clearAuthHeaders();
  if (token) {
    Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  if (adminToken) {
    Api.defaults.headers.common["AdminAuthorization"] = `Bearer ${adminToken}`;
  }
};

export default Api;
