import Typography from 'components/base/typography/typography';
import styled from 'styled-components';
import { Colors } from 'styles/colors';

export const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
        margin-bottom: 24px;
    }

    ${Typography.H1} {
        font-size: 18px;
        margin-bottom: 8px;
    }
    ${Typography.P} {
        font-size: 14px;
        color: ${Colors.Gray};
        margin-bottom: 25px;
    }
`;
