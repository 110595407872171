import React from 'react';
import Icon from '../icon/icon';
import { IconType } from '../icon/icon.types';
import * as S from './loader.styles';

const Loader: React.FC = () => {
    return (
        <S.LoaderContainer>
            <S.LoaderSpinner>
                <Icon type={IconType.Loader} />
            </S.LoaderSpinner>
        </S.LoaderContainer>
    );
};

export default Loader;
