import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Routes } from 'routes';
import * as S from './navigation.styles';

interface NavigationItemProps {
    title: string;
    toRoute: Routes;
    routesForActive: Routes[];
}

const NAVIGATION_ITEMS: NavigationItemProps[] = [
    {
        title: 'Поиск стандартов',
        toRoute: Routes.StandartsFederal,
        routesForActive: [Routes.StandartsFederal, Routes.StandartsAll, Routes.StandartsMedOrganization],
    },
];

const Navigation: React.FC = () => {
    const history = useHistory();
    const routeMatch = useRouteMatch();

    const onItemClick = (item: NavigationItemProps) => {
        history.push(item.toRoute);
    };

    return (
        <S.NavigationContainer>
            {NAVIGATION_ITEMS.map((item, key) => {
                const { title } = item;
                const isActive = item.routesForActive.includes(routeMatch.path as Routes);

                return (
                    <S.Item key={key} isActive={isActive} onClick={() => onItemClick(item)}>
                        {title}
                    </S.Item>
                );
            })}
        </S.NavigationContainer>
    );
};

export default Navigation;
