import Modal from 'components/base/modal/modal';
import useSearchStandartsContext from 'pages/standarts/hooks/useSearchStandartsContext';
import React from 'react';
import * as S from './ask-login-modal.styles';
import { ReactComponent as LockEmptyStateImage } from 'assets/images/lock-empty-state.svg';
import Typography from 'components/base/typography/typography';
import { Colors } from 'styles/colors';
import Button from 'components/base/button/button';

const AskLoginModal: React.FC = () => {
    const { isAskLoginModalShowing, toggleAskLoginModal } = useSearchStandartsContext();
    return (
        <Modal isOpen={isAskLoginModalShowing} hideOnOverlayClicked={true} onHide={() => toggleAskLoginModal(false)}>
            <S.Container>
                <LockEmptyStateImage />
                <Typography.H1 style={{ fontSize: 24, marginBottom: 16 }}>
                    Хотите создать свои медицинские стандарты?
                </Typography.H1>
                <Typography.SPAN style={{ fontSize: 14, color: Colors.Gray, marginBottom: 40 }}>
                    Войдите в свой аккаунт
                </Typography.SPAN>
                <Button label="Вход" onClick={() => toggleAskLoginModal(false)} />
            </S.Container>
        </Modal>
    );
};

export default AskLoginModal;
