import { useState } from 'react';

export function useFormedRecommendations() {
    const [formedRecommendations, setFormedRecommendations] = useState<string[]>([]);

    const switchRecommendation = (recStr: string, action: 'add' | 'remove') => {
        console.log(recStr, action);

        if (action === 'add') {
            setFormedRecommendations((prev) => prev.concat(recStr));
        } else {
            setFormedRecommendations((prev) => {
                const removedInd = prev.findIndex((str) => str === recStr);
                if (removedInd !== -1) {
                    return prev.filter((_, ind) => ind !== removedInd);
                }
                return prev;
            });
        }
    };

    return { formedRecommendations, switchRecommendation };
}
