import styled from 'styled-components';
import { Colors } from 'styles/colors';
import * as Logo from 'components/base/logo/logo.styles';
import * as Button from 'components/base/button/button.styles';
import * as Navigation from '../navigation/navigation.styles';

export const HeaderContainer = styled.div`
    background-color: ${Colors.White};
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 50px rgba(40, 40, 40, 0.05);

    ${Logo.Container}, ${Button.ButtonContainer} {
        margin: auto 0;
    }

    ${Button.ButtonContainer} {
        margin-right: 40px;
    }

    ${Logo.Container} {
        margin-left: 24px;
    }

    ${Navigation.NavigationContainer} {
        margin-left: 60px;
    }
`;

export const LinkContainer = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;

    a {
        color: ${Colors.Blue};
        cursor: pointer;
    }
`;
