import { AxiosError, ServiceType, StandartByIdResponse } from '@med-standarts/api';
import React, { createContext, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useStandartStateHandler } from './hooks/useStandartStateHandler';
import { StandartState } from './models/standart-state.model';
import { StandartSection } from './models/standart-section.model';
import { MenuSectionType } from './models/menu-section-type';
import { useExistsMenuSections } from './hooks/useExistsMenuSections';
import { useScrollNavigation } from './hooks/useScrollNavigation';
import { useFormedRecommendations } from './hooks/useFormedRecommendations';

export interface IStandartContext {
    standart: StandartState;
    activeSection: MenuSectionType;
    existsSections: Map<StandartSection, ServiceType[]>;
    isRecModalShowing: boolean;
    formedRecommendations: string[];
    switchRecommendation: (recStr: string, action: 'add' | 'remove') => void;
    toggleRecModal: React.Dispatch<React.SetStateAction<boolean>>;
    getIsSectionExists: (section: MenuSectionType) => boolean;
    addAnchor: (section: MenuSectionType, sectionElement: HTMLDivElement | null) => void;
    setScrollContainer: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
    scrollToAnchor: (section: MenuSectionType) => void;
}

export const StandartContext = createContext<IStandartContext>(null!);

interface Props {
    queryResult: UseQueryResult<StandartByIdResponse, AxiosError<any>>;
}

const StandartProvider: React.FC<Props> = ({ children, queryResult }) => {
    const standart = useStandartStateHandler(queryResult);
    const [activeSection, setActiveSection] = useState<MenuSectionType>([StandartSection.Info, null]);
    const { existsSections, getIsSectionExists } = useExistsMenuSections(standart.data);
    const { addAnchor, setScrollContainer, scrollToAnchor } = useScrollNavigation(setActiveSection);
    const [isRecModalShowing, toggleRecModal] = useState<boolean>(false);
    const { formedRecommendations, switchRecommendation } = useFormedRecommendations();

    return (
        <StandartContext.Provider
            value={{
                standart,
                activeSection,
                existsSections,
                isRecModalShowing,
                formedRecommendations,
                switchRecommendation,
                toggleRecModal,
                getIsSectionExists,
                addAnchor,
                setScrollContainer,
                scrollToAnchor,
            }}
        >
            {children}
        </StandartContext.Provider>
    );
};

export default StandartProvider;
