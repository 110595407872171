import { createGlobalStyle, css } from 'styled-components';
import { Colors } from './colors';

export const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
    }
    html {
        scroll-behavior: smooth;
    }
    * {
        box-sizing: border-box;
        color: ${Colors.DarkBlue};
        font-family: "SF Pro Display";
        font-weight: 400;
        outline: none;
    }

    a {
        text-decoration: none;
    }

    p {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
    }
`;

export const OverlayStyle = css`
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    background: rgba(23, 43, 77, 0.15);
    backdrop-filter: blur(5px);
    transition: opacity 0.3s;
`;

export const HiddenScrollBar = css`
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ScrollBarStyle = css`
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    scrollbar-color: ${Colors.Gray} ${Colors.LightGray};

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${Colors.Gray};
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
    }
    &::-webkit-scrollbar-track {
        background-color: ${Colors.LightGray};
    }
`;
